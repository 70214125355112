/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutAddressTable {
    .CheckoutAddressTable {
        &-Button {
            @include mobile {
                margin: 0 0 2rem 0;
            }
        }
    }

    &-Default {
        display: none;
    }

    &-Button {
        --check-mark-opacity: 0;

        border: 1px solid var(--color-gray);
        border-radius: 8px;
        transition-property: border-color, background, border-color;
        transition-duration: 150ms;
        transition-timing-function: ease-in;
        width: 100%;
        height: 100%;
        cursor: pointer;

        .AddressContainer {
            height: 100%;
        }

        .AccountAddress {
            padding: 3rem 2rem 0;
            cursor: pointer;

            @include mobile {
                padding: 2rem;
            }

            address {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: start;

                p {
                    margin: 0;
                    padding: 5px 10px;
                    font-size: 16px;
                }

                .Address {
                    font-weight: 600;
                }

                .Address2 {
                    padding-block-start: 5px;
                    font-weight: 400;
                }

                .FullName {
                    padding-block-start: 1rem;
                    font-weight: 600;
                }

                .Address-Action {
                    display: flex;
                    
                    button {
                        color: var(--color-black);
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: capitalize;
                        text-decoration: underline;

                        &:hover {
                            color: var(--primary-base-color);
                        }

                        &:first-of-type {
                            padding-inline-start: 10px;
                        }
                    }

                    @include mobile {
                        .Action-Buttons {
                            display: flex;
                        }
                    }

                    .Action-Buttons {
                        display: flex;
                    }
                }

                .Address-Action-Container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .UseAddress button {
                        padding: 4px, 16px, 4px, 16px;
                        border-radius: 8px;
                        font-size: 14px;
                        font-weight: 600;
                        color: #FFFFFF;
                        background: #E84620;
                        text-decoration: none;
                        height: 32px;
                    }
                }
            }

            .Addresstype {
                opacity: 0.6;
                height: min-content;
                background: #3c5f46;
                color: var(--color-white);
                border-radius: 8px 0px 0px 8px;
                padding: 8px 24px;
                font-size: 12px;
                position: absolute;
                inset-block-start: 20px;
                inset-inline-end: 0px;
                font-weight: 600;

                @include mobile {
                    font-size: 10px;
                }
            }

            .DefaultAddress {
                &-Wrapper {
                    display: flex;
                    justify-content: space-between;
                    padding-inline: 10px;
                }

                &-label {
                    //Hide the label as not the part of Figma
                    display: none;
                    background-color: #e6d93b;
                    padding: 3px 5px;
                    border-radius: 8px;
                    font-weight: 500;

                    // @include smallmobile {
                    //     font-size: 12px;
                    // }
                }
            }
        }

        .KeyValueTable-Heading {
            .RadioButtonIcon {
                position: absolute;
                inset-inline-end: 10px;
                inset-block-start: 12px;
                display: inline-block;
            }
        }

        .KeyValueTable {
            &-Wrapper {
                table {
                    .Address-Action {
                        .DefaultAddress-label {
                            display: none;
                        }
                    }
                }
            }

            .Action-Buttons {
                @include mobile {
                    display: flex;

                    button:first-child {
                        padding-inline-start: 0;
                    }
                }
            }
        }

        p {
            opacity: 0.6;
            
            td {
                font-size: 14px;
                padding: 6px 10px;
            }
        }

        @include desktop {
            margin-block-end: 20px;
        }

        &:not(&_isSelected):hover {
            background-color: #F9F6F4;
            --check-mark-opacity: 1;
            --checkout-address-table-border: var(--checkout-address-table-hover-border);
            --check-mark-color: var(--checkout-address-table-check-mark-hover-color);
        }

        &_isSelected {
            border: none;
            background-color: #FFFFFF;
            --check-mark-opacity: 1;
            --checkout-address-table-border: var(--checkout-address-selected-color);
            --check-mark-color: var(--checkout-address-table-check-mark-selected-color);

            p {
                opacity: 1;
            }

            .AccountAddress {
                .Addresstype {
                    opacity: 1;
                }
            }
        }
    }

    &-SuccessPage p {
        opacity: 1;
    }

    &:has(.CheckoutAddressTable-SuccessPage) {
        pointer-events: none;

        .CheckoutAddressTable-Button:hover {
            background-color: #FFFFFF;
        }
    }
}
