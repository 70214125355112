/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutDeliveryOption {
    padding: 16px;
    max-width: 421px;
    width: 100%;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    background: #FFFFFF;

    @include mobile {
        padding: 12px 16px;
        max-width: 100%;
    }

    &-Button {
        align-items: center;
        font-size: 16px;
        font-weight: 600;
    }

    &:first-child {
        border-top: 1px solid #DFDFDF;
    }

    &_isActive:first-child {
        border-top: 1px solid var(--color-black);
    }

    &_isActive {
        border: 1px solid var(--color-black);
    }

    &-Message {
        margin-block-start: 1rem;
    }

    .Field-Wrapper {
        width: auto;
    }

    .Field [type='radio']:checked + .input-control {
        border-color: #000000;
        border: 2px solid;
        min-width: 24px;
        height: 24px;

        &::after {
            height: 14px;
            width: 14px;
            inset-inline-start: 3px;
            inset-block-start: 3px;
        }
    }

    .Field [type='radio'] + .input-control {
        border: 2px solid #C7C6C6;
        min-width: 24px;
        height: 24px;

        // &:hover {
        //     min-width: 24px;
        //     height: 24px;
        //     border: 2px solid #e73b25;
        // }

        &::after {
            height: 14px;
            width: 14px;
            inset-inline-start: 3px;
            inset-block-start: 3px;
        }
    }

    .Field [type='radio']:hover:not(:disabled) + .input-control::after {
        --checkmark-color: #000000;
    }

    &:hover:not(.CheckoutDeliveryOption_isHoverExcluded) .input-control {
        border: 2px solid #e73b25;

        &::after {
            --checkmark-color: #000000;
        }
    }

    &-Row {
        @include smaller-mobiles {
            font-size: 12px;
        }
    }
}
