/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutFailure {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: start;
    gap: 25px;

    @include desktop {
        padding: 32px;
        background: #F9F9F9;
    }

    @include small-desktop {
        padding: 2rem;
    }

    @include mobile {
        grid-template-columns: 1fr;
        margin-block-end: 16px;
    }

    &-ContentWrapper {
        display: grid;
        gap: 2rem;
    }

    &-SuccessMessage {
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }

    &-OrderNumberWrapper {
        display: grid;
        gap: 1rem;

        .OrderNumber {
            font-size: 20px;
            font-weight: 600;

            @include mobile {
                font-size: 18px;
            }
    
            span {
                color: var(--primary-base-color);
            }
        }
    }

    &-Heading {
        font-size: 28px;
        font-weight: 700;
        text-transform: inherit;

        @include mobile {
            font-size: 18px;
            margin: 0;
        }
    }

    &-ButtonWrapper {
        position: revert;
        padding: 0;
        width: 100%;
        border: none;
        background: unset;
    }

    &-ContinueButton {
        font-weight: 500;
        border-radius: 8px;
        gap: 1rem;

        &:not([disabled]):hover {
            border-radius: 8px;
        }

        svg {
            margin-block-start: 2px;
        }
    }

    &-ContactLink {
        display: flex;
        gap: 3px;
        flex-direction: column;

        div, a {
            font-size: 14px;
        }

        a {
            border-bottom: 1px solid;
            color: #000000;
        }

        p {
            font-size: 20px;
            font-weight: 700;

            @include mobile {
                font-size: 16px;
            }
        }
    }

    &-ShoppingLink {
        a {
            color: #000000;
            font-weight: 600;
            border-bottom: 1px solid #000000;
            white-space: nowrap;

            &:hover {
                border-bottom: 1px solid;
            }
        }
    }

    .CheckoutOrderSummary-OrderTotals ul {
        @include mobile {
            padding-block-end: 2rem;
        }
    }



    .CheckoutAddressTable-Button .AccountAddress {
        padding: 14px;
    }


    .CartItem-Wrapper_isMobileLayout {
        @include mobile {
            border-bottom: 1px solid #dfdfdf;
        }
    }

    .Checkout-SummaryOverlay {
        margin-inline: -14px;

        .Checkout-SummaryHeading h3 {
            border-inline-end: none;
        }
    }
}

body:has(.CheckoutFailure) {
    .Breadcrumbs-List {
        padding-inline-start: 32px;
    }

    .Router-MainItems {
        @include desktop {
            background: #F9F9F9;
        }
    }
}
