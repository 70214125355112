/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutSuccess {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: start;
    gap: 25px;

    @include desktop {
        padding: 32px;
    }

    @include small-desktop {
        padding: 2rem;
    }

    @include mobile {
        grid-template-columns: 1fr;
        margin-block-end: 16px;
    }

    &-ContentWrapper {
        display: grid;
        gap: 2rem;
    }

    &-SuccessMessage {
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }

    &-OrderNumberWrapper {
        display: grid;
        gap: 1rem;

        .OrderNumber {
            font-size: 20px;
            font-weight: 700;

            @include mobile {
                font-size: 18px;
            }
        }

        .CustomerEmail {
            font-size: 14px;

            span {
                font-weight: 600;
            }
        }
    }

    &-Icon {
        width: fit-content;
    }

    &-Heading {
        font-size: 28px;
        font-weight: 700;
        height: 36px;
        text-transform: none;

        @include mobile {
            font-size: 18px;
            margin: 0;
        }
    }

    &-ButtonWrapper {
        position: revert;
        padding: 0;
        width: 100%;
        border: none;
        background: unset;
    }

    &-ContinueButton {
        font-weight: 600;
        border-radius: 8px;
        margin-block-start: 32px;
        font-size: 16px;
        padding: 12px 16px 12px 16px
        ;

        &:not([disabled]):hover {
            border-radius: 8px;
        }

        svg {
            padding-block-start: 3px;
        }
    }

    .CheckoutProcess_Parent {
        @include mobile {
            .CheckoutProcess_Separator {
                display: flex;
                flex-direction: column;
            }   

            .CheckoutProcess_firstblock {
                border-right: none;
                border-bottom: 1px solid #000000;
            } 

            .CheckoutProcess_Secondblock {
                padding-inline-end: 0px;
                padding-inline-start: 0px;
                font-size: 12px;
            }

            .CheckoutProcess_Video {
                width: 334px;
            }

            .CheckoutProcess_Watch {
                padding-block-end: 0px;
                padding-block-start: 16px;
            }
            
            .CheckoutProcess_Download {
                font-size: 12px;
            }

            .CheckoutProcess_Needhelp {
                font-size: 12px;
            }
        }
    }

    .Product-list {
        max-width: var(--content-wrapper-width);
        width: 100%;
        margin-block-start: 2rem;

        .ListHeading {
            font-size: 20px;
            font-weight: 600;
        }

        .CartPage-Items {
            border-block-start: 1px solid var(--primary-divider-color);

            .CartItem {
                &-ProductInfo {
                    align-items: center;

                    @include mobile {
                        column-gap: 1rem;
                    }

                    .Image {
                        .TextPlaceholder_length_block::after {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                
                &::before {
                    display: none;
                }

                &-Details_Inner {
                    justify-content: flex-start;

                    @include mobile {
                        row-gap: 1rem;
                    }
                }

                &-Heading {
                    font-size: 16px;
                    margin: 0;
                }

                &-Item {
                    justify-content: flex-start;

                    &:first-child {
                        color: #a3a3a3;
                    }
                }

                &-Price {
                    display: flex;
                    column-gap: 10px;

                    span {
                        font-size: 16px;

                        @include mobile {
                            font-size: 12px;
                        }
                    }
                }

                &-DeliveryDate {
                    text-align: end;

                    @include mobile {
                        margin: 0;
                    }

                    .Text {
                        font-size: 12px;
                        color: #585858;

                        @include mobile {
                            font-size: 10px;
                        }
                    }

                    .Date {
                        font-size: 16px;
                        font-weight: 600;

                        @include mobile {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    } 

    .CmsBlock-Wrapper {
        .CheckoutProcess_Parent {
            @include mobile {
                .CheckoutProcess_Separator {
                    display: flex;
                    flex-direction: column;
                }   

                .CheckoutProcess_firstblock {
                    border-right: none;
                    border-bottom: 1px solid #000000;
                } 

                .CheckoutProcess_Secondblock {
                    padding-inline-end: 0px;
                    padding-inline-start: 0px;
                    font-size: 12px;
                }

                .CheckoutProcess_Video {
                    width: 334px;
                }

                .CheckoutProcess_Watch {
                    padding-block-end: 0px;
                    padding-block-start: 16px;
                }
                
                .CheckoutProcess_Download {
                    font-size: 12px;
                }

                .CheckoutProcess_Needhelp {
                    font-size: 12px;
                }
            }
        }
    }

    &-OrderBlocks {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-block-start: 2rem;

        @include mobile {
            flex-direction: column;
            padding-block-end: 85px;
        }

        .ShippingAddress {
            &-Wrapper {
                @include mobile {
                    border-block-end: 1px solid var(--primary-divider-color);
                }
            }

            &-Heading {
                font-size: 16px;
                margin-block-end: 26px;
                font-weight: 500;
            }

            &-Details {
                p {
                    margin-block-end: 12px;
                }

                .ShippingAddress {
                    &-Phone {
                        margin-block-end: 2rem;
                    }
                }
            }

            &-Name {
                font-weight: 500;
            }

            &-Shipping,
            &-Payment {
                display: flex;
                column-gap: 2rem;
                font-size: 16px;
                font-weight: 500;

                .Method {
                    font-size: 14px;
                    font-weight: normal;

                    span {
                        color: #9B9B9B;
                    }
                }
            }
        }

        .OrderSummary {
            &-Wrapper {
                @include mobile {
                    padding-block-start: 2rem;
                }
            }

            &-Heading {
                font-size: 16px;
                margin-block-end: 26px;
                font-weight: 500;
            }

            &-Details {
                .OrderSummaryItem {
                    &-row, &-Tax {
                        font-size: 16px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-block-end: 20px;
                        column-gap: 6rem;

                        .label {
                            max-width: 400px;
                            
                            @include mobile {
                                flex-basis: 60%;    
                            }

                            span {
                                color: #9B9B9B;
                            }
                        }
                    }

                    &-Tax {
                        flex-wrap: wrap;

                        .detail {
                            flex-basis: 100%;
                            font-size: 12px;
                        }
                    }

                    &-Total {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        column-gap: 6rem;
                        font-size: 17px;
                        font-weight: 500;
                        padding-block-start: 2rem;
                        border-block-start: 1px solid var(--primary-divider-color);

                        .value {
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }

    .CheckoutOrderSummary-DeliveryInsurance {
        display: none;
    }

    //Removed multiple price issue 

    .CartItem-ProductInfo {
        .ProductPrice.CartItem-Price {
            display: none;
        }
    }

    .CheckoutOrderSummary-OrderTotals ul {
        @include mobile {
            padding-block-end: 2rem;
        }
    }

    .CheckoutAddressTable-Button .AccountAddress {
        padding: 14px;

        @include mobile {
            padding-inline: 4px;
        }
    }

    .AlertMsg {
        font-size: 14px;
        font-weight: 600;
        color: var(--primary-base-color);
    }

    .Checkout-SummaryOverlay {
        margin-inline: -14px;

        .Checkout-SummaryHeading h3 {
            border-inline-end: none;
        }
    }
}

body:has(.CheckoutSuccess) {
    .Breadcrumbs-List {
        padding-inline-start: 32px;
    }

    .Router-MainItems {
        @include desktop {
            background: #F9F9F9;
        }
    }
}
