/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@keyframes slideIn {
    0% {
        transform: translateX(900px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100vw);
    }
}

.CheckoutAddressBook {
    @include mobile {
        margin: 24px 0;
    }

    .Checkout {
        display: block;

        &-Heading {
            font-size: 18px;
            font-weight: 600;
            margin: 0;

            @include mobile {
                font-size: 16px;
            }
        }

        &-Default {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid #DFDFDF;

            &.active {
                border: 1px solid #0F0F0C;
            }

            button {
                font-size: 14px;
                font-weight: 600;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: var(--primary-base-color);
                }
            }
        }

        &-Custom {
            .Checkout-Heading {
                border-bottom: 1px solid #c5cbd5;
                padding-block-end: 2rem;
            }
        }
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            margin-block-end: 24px;
        }


        &.OutsidePopup {
            .CheckoutAddressTable {
                display: none;
            }

            .CheckoutAddressTable:has(.CheckoutAddressTable-Button_isSelected) {
                display: block;
            }

            .Address-Actions {
                display: none;
            }

            .Address-Action {
                .Action-Buttons.Edit,.Action-Buttons.Delete {
                    display: none;
                }
            }
        }
    }

    .CheckoutAddressBook {
        &-CustomAddressWrapper {
            display: grid;
            margin: 30px 0 24px 0;

            @include mobile {
                margin: 20px 0;
            }

            h4 {
                font-size: 20px;
                font-weight: 600;

                @include mobile {
                    font-size: 16px;
                }
            }

            .TopContent {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 95%;
            }
    
            .CheckoutAddressBook-Button {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                color: var(--color-black);
                // cursor: pointer;
                border: none;
                column-gap: 5px;
                text-decoration: underline;

                &:hover {
                    color: var(--primary-base-color);

                    svg {
                        path {
                            fill: var(--primary-base-color);
                        }
                    }
                }

                span {
                    text-transform: capitalize;
                }

                @include mobile {
                    font-size: 12px;
                    flex-basis: 50%;
                }
            }
        }

        &-Button {
            &_isCustomAddressExpanded {
                display: block;
            }
        }
    }

    .FieldForm-Fields, .FieldGroup {
        row-gap: 2rem;
        
        @include wide-desktop {
            grid-column-gap: 6rem;

            > *:last-child:nth-child(odd) {
                grid-column: span 1;

                label {
                    font-size: 14px;
                    color: #6F6F6F;
                }
            }
        }
    }

    .FieldGroup {
        &-Wrapper {
            &_multipleFields.FieldGroup-Wrapper_oddAddresses {
                @include wide-desktop {
                    .FieldGroup {
                        > *:first-child {
                            grid-column-end: unset;
                        }
                    }
                }
            }

            &_street {
                .Field-Wrapper {
                    &:nth-child(n+2) {
                        display: none;
                    }
                }
            }
        }
    }

    .Button_block_CheckoutAddressBook {
        width: 100%;
        max-width: 325px;
        border-radius: 8px;

        @include mobile {
            max-width: 100%;
        }

        &:hover {
            border-radius: 8px;
        }
    }

    .StoreSwitcherV2 {
        button {
            width: 100%;
            max-width: 325px;
            border-radius: 8px;

            &:not([disabled]):hover {
                border-radius: 8px;
            }
        }
    }
}

.ChangeAddressPopup_isVisible {
    background: #00000077;
    z-index: 99999;

    @include mobile {
        inset-block-start: 0;
        height: 100%;
    }

    .Popup {
        &-Content {
            max-height: 100%;
            height: 100%;
            border-radius: 8px 0 0 8px;
            inset-inline-end: 0;
            inset-inline-start: unset;
            position: absolute;
            background: #F9F9F9;
            animation: slideIn .5s forwards;

            @include mobile {
                min-width: 95%;
                height: 100%;
            }

            // @include desktop {
            //     animation: slideIn .5s forwards;
            // }

            .Change-Address {
                display: none;
            }
        }

        &-Header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-block-end: 20px;
            border-bottom: 1px solid #e0e0e0;

            .Popup-CloseBtn {
                position: relative;
            }

            h3 {
                margin-block-end: 0;
            }
        }

        &-Heading {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .CheckoutAddress {
        &Book-Wrapper {
            grid-template-columns: none;
            margin-block-start: 20px;
        }

        &Table {
            &-Button {
                background: #FFFFFF;

                p {
                    opacity: 1;
                }
            }

            .Action-Buttons button {
                font-size: 16px;
            }
        }

        &Book-AddNewAddressButton button {
            font-size: 14px;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: var(--primary-base-color); 
            }

            @include mobile {
                padding-block-end: 20px;
            }
        }
    }
}

.ChangeAddressPopup.willClose .Popup-Content {
    animation: slideOut .5s forwards;
}

