/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';



.CheckoutPayment {
    margin-block-end: 15px;
    border-radius: 8px;
    border: 1px solid #DFDFDF;
    padding-block: 0px;
    background: #FFFFFF;

    &:first-child {
        border-block-start: 1px solid #DFDFDF;
    }

    &_isSelected {
        background-color: #FFFFFF;
        border-color: var(--color-black);
    }

    @include mobile {
        padding-block: 0px;
    }

    &-cardBlock {   
        padding-block: 19px;
        padding-inline: 10px;

        @include mobile {
            padding-block: 17px;
        }
    }

    &-paymentInfo {
        border-block-end: 1px solid #C7C5C3;

        p {
            padding: 12px;
            margin-block-end: 0px;
            font-size: 12px;
            text-align: justify;
        }
    }

    &-paymentInfoVideo {
        border-bottom: 1px solid #C7C5C3;

        .CmsBlock-Wrapper {
            padding: 12px !important; /* stylelint-disable-line declaration-no-important */

            .paymentInfoVideoNote {
                display: flex;
                align-items: center;
                margin-block-end: 10px;
                column-gap: 5px;

                p {
                    margin-block-end: 0px;
                    font-weight: 600;
                    color: var(--color-black);
                }
            }

            .paymentInfoVideoText {
                line-height: 2;
            }

            .downloadLink {
                display: flex;
                align-items: center;

                a {
                    color: var(--color-black);
                    text-decoration: underline;
                    font-weight: 500;
                }
            }
        }
    }

    &-Button {
        --check-mark-opacity: 0;

        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
        padding-inline: 6px 12px;

        &.BitCoin {
            flex-direction: row;
            justify-content: space-between;
            // flex-wrap: wrap;
            align-items: center;
        }

        &.CardPayment {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
        }

        .Field {
            margin-block-start: 0px;
            display: flex;

            &-RadioLabel {
                .input-control {
                    border: 2px solid #959499;
                }
            }
        }

        .Field [type='radio']:checked + .input-control {
            border-color: #000000;
            border: 2px solid;
            min-width: 24px;
            height: 24px;
    
            &::after {
                height: 14px;
                width: 14px;
                inset-inline-start: 3px;
                inset-block-start: 3px;
            }
        }
    
        .Field [type='radio'] + .input-control {
            border: 2px solid #C7C6C6;
            min-width: 24px;
            height: 24px;
    
            // &:hover {
            //     min-width: 24px;
            //     height: 24px;
            //     border: 2px solid #e73b25;
            // }
    
            &::after {
                height: 14px;
                width: 14px;
                inset-inline-start: 3px;
                inset-block-start: 3px;
            }
        }
    
        .Field [type='radio']:hover:not(:disabled) + .input-control::after {
            --checkmark-color: #000000;
        }
    
        &:hover:not(.CheckoutDeliveryOption_isHoverExcluded) .input-control {
            border: 2px solid #e73b25;
    
            &::after {
                --checkmark-color: #000000;
            }
        }

        &_isSelected {
            padding-block-end: 12px;
            border-bottom: 1px solid #DFDFDF;
        }

        label {
            @include smaller-mobiles {
                font-size: 12px;
            }
        }
    }

    &-PlaceOrderBlock {
        padding: 12px;
        display: flex;
        flex-direction: column;
        row-gap: 14px;

        h4 {
            font-weight: 600;
        }

        .Field-Wrapper_type_checkbox {
            .CheckoutPayment-AgreeCheckBox {
                p {
                    margin-block-end: 0px;
                }

                .Field-CheckboxLabel {
                    font-size: 14px;
        
                    &:not([class*='_isDisabled']):hover {
                        color: var(--color-black);
                    }
        
                    .input-control {
                        border: 2px solid #959499;
                    }
        
                    p {
                        margin-block-end: 0px;
        
                        a {
                            color: var(--color-black);
                            text-decoration: underline;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

    &-checkBoxLink {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        a {
            color: var(--color-black);
            text-decoration: underline;
        }

        .Field-Wrapper_type_checkbox {
            width: auto;
        }
    }

    &-orderTotal {
        display: flex;
        background-color: #F0EFEF;
        padding: 14px 16px;
        justify-content: space-between;

        p {
            margin-block-end: 0px;
            font-weight: 600;
        }
    }

    &-StickyButtonWrapper {
        .CheckoutPayment-Button {
            width: 100%;
            border-radius: 8px;
            align-items: center;

            &:hover {
                border-radius: 8px;

                @include desktop {
                    padding-inline: 6px 12px;
                }
            }
        }

        .Button[disabled] {
            background-color: #C7C6C6;
            border-color: #C7C6C6;
        }
    }

    &-paymentCardFooter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin-block-end: 0px;
            font-size: 12px;

            @include tablet {
                font-size: 10px;
            }
        }
    }

    &-CardPaymentInfo {
        font-size: 12px;
        font-weight: 400;
        padding-block-start: 10px;
        margin-block-start: 10px;
        text-align: justify;
        border-top: 1px solid #DFDFDF;
    }
}
