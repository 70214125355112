/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutBilling {
    .Checkout {
        &-NoPaymentMethod {
            font-size: 15px;
        }

        &-StoreCredit {
            margin-block: 2rem;

            .Field-Wrapper_type_checkbox {
                display: block;
                padding-block-start: 0;
            }
        }

        &-StoreCreditLabel {
            font-size: 16px;
            font-weight: bold;
            margin-block-end: 20px;
        }

        &-StoreCreditData {
            font-size: 14px;
            color: var(--primary-base-color);
            font-weight: 500;
        }
    }
}
