/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


.CheckoutPayments {
    &-Methods {
        width: 100%;
        max-width: 421px;
        margin-block: 2rem;

        @include mobile {
            width: 100%;
        }

        .Checkout-ShippingTitle {
            font-size: 18px;
            font-weight: 600;
            margin-block-end: 24px;
        }
    }

    .Field-Wrapper {
        &_type {
            &_checkbox {
                padding: 10px 0;
            }
        }
    }
    
    .Checkout {
        &-Heading {
            font-size: 18px;
            font-weight: 600;
            text-transform: capitalize;
            margin: 0;
            padding: 0;
            border: none;
            
            @include mobile {
                font-size: 16px;
            }
        }
        
        &-Default {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid #DFDFDF;

            &.active {
                border: 1px solid #0F0F0C;
            }

            h2 {
                font-size: 18px;
                font-weight: 600;
                text-transform: capitalize;
                margin: 0;

                @include mobile {
                    font-size: 16px;
                }
            }

            button {
                font-size: 14px;
                font-weight: 600;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: var(--hollow-button-hover-color);
                }
            }
        }
    }

    &-CustomerNotesField {
        .Field-Wrapper_type_textarea .Field-LabelContainer {
            inset-block-end: 4rem;
        }
    }
}
